import React from 'react';
import { Construction, Mail, Clock } from 'lucide-react';
import Logo from './Images/logo.png'

const UnderConstruction = () => {
  return (
    <div className="min-h-screen bg-navy flex items-center justify-center p-4">
      <div className="bg-navy-light rounded-lg shadow-lg p-8 max-w-md w-full text-center">
        <div className="mb-6">
          <img src= {Logo} alt="Treff & Lowy PLLC Logo" className="mx-auto rounded-full border-4 border-gold" />
        </div>
        {/* <h1 className="text-4xl font-bold text-gold mb-4">Treff & Lowy PLLC</h1> */}
        <div className="flex items-center justify-center text-white text-xl mb-6">
          <Construction className="mr-2 text-gold" />
          <span>Website Under Construction</span>
        </div>
        <p className="text-gray-100 mb-8">We're working diligently to bring you our new website. Stay tuned for our launch!</p>
        <div className="flex flex-col space-y-4">
          <a 
            href="mailto:info@trefflowy.com" 
            className="inline-flex items-center justify-center bg-gold hover:bg-gold-dark text-navy font-bold py-2 px-4 rounded transition duration-300"
          >
            <Mail className="mr-2" />
            Contact Us
          </a>
          <div className="text-gray-100 flex items-center justify-center">
            <Clock className="mr-2 text-gold" />
            <span>Coming Soon</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderConstruction;