import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Team from "./components/Team";
import Footer from "./components/Footer";
import Services from "./components/Services";
import Expertise from "./components/Expertise";
import About from "./components/About";
import Contact from "./components/Contact";
import './App.css'
import UnderConstruction from "./UnderConstruction";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function AppContent() {
  const [scrollY, setScrollY] = useState(0);
  const { pathname } = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    if (pathname === '/') {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [pathname]);

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar scrollY={scrollY} />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={
            <>
              <Hero scrollY={scrollY} />
              <About />
              <Services />
              <Expertise />
              <Team />
              <Contact/>
            </>
          } />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/expertise" element={<Expertise />} />
          <Route path="/team" element={<Team />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
<UnderConstruction/>
    </Router>
  );
}

export default App;