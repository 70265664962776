import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, ChevronDown } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import logo from '../Images/logo.png'

const NavLink = ({ to, children, subItems = [] }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative group"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link
        to={to}
        className="text-white hover:text-gold transition-colors duration-300 flex items-center text-sm uppercase tracking-wider"
      >
        {children}
        {subItems.length > 0 && <ChevronDown className="ml-1 w-3 h-3" />}
      </Link>
      {subItems.length > 0 && (
        <AnimatePresence>
          {isHovered && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.2 }}
              className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-20"
            >
              {subItems.map((item, index) => (
                <Link
                  key={index}
                  to={item.to}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  {item.label}
                </Link>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </div>
  );
};

const Navbar = ({ scrollY }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
  
    useEffect(() => {
      const handleResize = () => setIsMenuOpen(false);
      const handleScroll = () => setIsScrolled(window.scrollY > 20);
  
      window.addEventListener('resize', handleResize);
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const navItems = [
      { to: "/", label: "Home" },
      { to: "/about", label: "About" },
      { to: "/services", label: "Services", subItems: [
        { to: "/services/residential", label: "Residential Real Estate" },
        { to: "/services/commercial", label: "Commercial Real Estate" },
        { to: "/services/litigation", label: "Real Estate Litigation" },
      ]},
      { to: "/expertise", label: "Expertise" },
      { to: "/team", label: "Our Team" },
      { to: "/contact", label: "Contact" },
    ];
  
    return (
        <nav className={`fixed w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-navy bg-opacity-90 backdrop-blur-md' : 'bg-transparent'}`}>
          <div className="container mx-auto px-4 relative">
            {/* Logo Container */}
            <motion.div 
  className="flex justify-center items-center w-full h-20 absolute top-1 left-0 right-0" 
  initial={{ opacity: 0, y: -100 }}
  animate={{ 
    opacity: scrollY > 300 ? 1 : 0, 
    y: scrollY > 300 ? 0 : -100,
  }}
  transition={{ duration: 0.5 }}
>
  <Link to="/">
    <img
      src={logo}
      alt="Treff&Lowy PLLC Logo"
      className="h-80 object-contain" 
    />
  </Link>
</motion.div>




  
          {/* Navigation Items */}
          <div className="flex justify-between items-center h-24">
            <div className="hidden md:flex space-x-6 items-center">
              {navItems.slice(0, 3).map((item, index) => (
                <NavLink key={index} to={item.to} subItems={item.subItems}>
                  {item.label}
                </NavLink>
              ))}
            </div>
            <div className="flex-1" /> {/* Spacer */}
            <div className="hidden md:flex space-x-6 items-center">
              {navItems.slice(3).map((item, index) => (
                <NavLink key={index} to={item.to} subItems={item.subItems}>
                  {item.label}
                </NavLink>
              ))}
            </div>
            <div className="md:hidden">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
                {isMenuOpen ? <X className="text-white" size={24} /> : <Menu className="text-white" size={24} />}
              </button>
            </div>
          </div>
        </div>
  
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="md:hidden bg-navy bg-opacity-95 backdrop-blur-md"
            >
              <div className="container mx-auto px-4 py-4">
                {navItems.map((item, index) => (
                  <div key={index} className="py-2">
                    <Link
                      to={item.to}
                      className="text-white hover:text-gold transition-colors duration-300 block text-lg"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      {item.label}
                    </Link>
                    {item.subItems && (
                      <div className="pl-4 mt-2 space-y-2">
                        {item.subItems.map((subItem, subIndex) => (
                          <Link
                            key={subIndex}
                            to={subItem.to}
                            className="text-gray-300 hover:text-gold transition-colors duration-300 block text-sm"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            {subItem.label}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
    );
  };
  
  export default Navbar;